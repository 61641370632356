import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Button, Element, Heading } from "react-bulma-components"
import { StaticImage } from "gatsby-plugin-image"

const NotSupportedPage = () => {
  const { t } = useTranslation(["page_home" /* , 'slugs' */])

  const tMeta = t("meta")
  const tHero = t("hero")

  const isBrowser = typeof window !== "undefined"

  if (!isBrowser) return <><>{' '}</></>

  return (
    <>
      <Seo title={tMeta.title} description={tMeta.description} />
      <section className="section">
        <div className="container is-max-desktop">
          <Heading size={4} renderAs="h1" textTransform="uppercase">
            Oups...
            {/* tHero.title */}
          </Heading>
          <Element className="content">
            Votre smartphone dispose-t-il bien d'une boussole, de gyroscopes et/ou d'accéléromètres permettant de rapporter son orientation dans l'espace ?
            <br />
            <br />
            Si vous pensez que oui :
            <ol>
              <li>Vérifiez que ces fonctionnalités sont bien activées sur votre téléphone.</li>
              <li>Vérifiez que votre application boussole système fonctionne et indique bien le Nord.</li>
              <li>Vérifiez que l'accès à ces fonctionnalités est bien autorisé pour votre navigateur web.</li>
              <li>Fermez complètement le navigateur web et tentez de réaccéder à la vue immersive</li>
            </ol>
          </Element>
          <Button renderAs={Link} to='/test' size='small' color={'primary'} outlined>Re-tester mon appareil</Button>
        </div>
      </section>
    </>
  )
}

export default NotSupportedPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
